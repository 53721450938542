import React from 'react';

import ImageItem from './index';
import { SimpleUser, User } from '@common/types/objects';

interface ImageItemUserProps extends React.PropsWithChildren<{}> {
  item: User | SimpleUser;
}

export const ImageItemUser = ({ item: user, ...props }: ImageItemUserProps) => (
  <ImageItem
    mask
    image={user.profile_img}
    name={user.full_name}
    {...props}
  />
);

export default ImageItemUser;
